import React from "react"
import Layout from "../components/layout"
import Row from "../components/row"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title={data.pages.title} description={data.pages.excerpt}/>
    <Row size="small" image={data.pages.background} id="DetailPage" classes="pt-200 pb-60">
      <article className="column col-12">
        <h1 className="uppercase t-50 mb-40 bold mt-0-imp">{data.pages.title}</h1>
        <div className="detail-page" dangerouslySetInnerHTML={{ __html: data.pages.content }} />
      </article>
    </Row>
  </Layout>
)
export const pageQuery = graphql`
  query DetailPageByPath($path: String!) {
    pages(path: { eq: $path }) {
      title  
      excerpt  
      background
      content
      path
    }
  }
`
